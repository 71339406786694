import React from 'react'
import './portfolio.css'

import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Fit Club Gym Pro',
    github: 'https://github.com/bowed89/fitclubpro-reactjs',
    demo: 'https://fitclubgympro.pages.dev/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Real State, sales & buy homes',
    github: 'https://github.com/bowed89/realstate_nextjs',
    demo: 'https://realstate-nextjs-umber.vercel.app/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'COVID Tracker',
    github: 'https://github.com/bowed89/covid19-tracker-reactjs',
    demo: 'https://covid19-tracker-n7g.pages.dev/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Orders App',
    github: 'https://github.com/bowed89/heroku-angular-app',
    demo: 'https://chupitos-app.web.app/#/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Control de Gastos',
    github: 'https://github.com/bowed89/control-gastos-react',
    demo: 'https://control-gastos.pages.dev'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recen Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

        {
          data.map(({ id, image, title, github, demo }) => (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank'>GitHub</a>
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
          ))
        }



      </div>
    </section>
  )
}

export default Portfolio